.layoutflow {
  flex-grow: 1;
  position: relative;
}

.layoutflow .controls {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.react-flow{
	height: 75vh !important;
}

.datasource-node-highschool{
  font-size: 22px;
  width: 100px;
  padding: 10px !important;
  font-weight: 500;
  text-align: center;
  background-color: white;
  border: 1px solid #9E9E9E;
  min-width: 140px;
}

.add-btn{
	color: white;
  position: absolute;
  z-index: 1;
  right: -8px;
  top: 1px;
  padding: 5px !important;
  border-radius: 5px;
}
.add-left-btn{
	color: white;
  position: absolute;
  z-index: 1;
  left: -8px;
  top: 1px;
  padding: 5px !important;
  border-radius: 5px;
}

.activity-head{
	font-size: 14px;
	min-width: 140px;
  padding: 5px !important;
}
.filter{
  background-color: #2196F3;
  padding-top: 5px !important;
}

.pagenate-text{
  position: relative;
  top: -8px;
  left: 29%;
}
.pagenate-parent{
  margin-top: 10px;
  padding: 0px 12px !important;
}
.pagenate-left{
  font-size: 20px;
}

.pagenate-right{
  font-size: 20px;
  float: right;
}